import CustomImage from 'shared/components/CustomImage'
import Link from 'next/link'
import { StarIcon } from '@heroicons/react/24/solid'
import ArtWorkGrid from './ArtWorkGrid'
import classNames from 'classnames'

type Props = {
  isLoggedIn: boolean
  isAuthFetching: boolean
}

const TopFold = (props: Props) => {
  const { isLoggedIn, isAuthFetching } = props

  return (
    <div className="relative">
      <div className="sticky top-[80%] z-[20] h-0  text-center">
        {!isAuthFetching && (
          <Link
            passHref
            href="/discover"
            className="relative z-20 inline-block cursor-pointer rounded-[30px] bg-black/10 bg-primary px-4 py-[14px] text-xs text-white transition-all hover:rotate-[-4deg] hover:drop-shadow-lg lg:px-5 lg:py-[18px] lg:text-xl lg:leading-none"
          >
            {isLoggedIn ? 'Start Discovering' : 'Get Started'}
          </Link>
        )}
      </div>
      <div className="sticky top-0 flex h-screen flex-col items-center justify-center">
        <div className="container mx-auto">
          <h1 className="text-center text-[60px] font-semibold leading-[90%] tracking-[-3px] lg:text-[140px] lg:tracking-[-7px]">
            Discover <br /> art you love
          </h1>
          <p className="mt-4 text-center text-[10px] uppercase opacity-50 mix-blend-difference lg:text-base">
            Featured In
          </p>
          <div className=" mt-4 flex flex-wrap items-center justify-center gap-1 lg:gap-2">
            <div className="relative m-2 h-[18px] min-w-[64px] bg-white lg:h-10 lg:min-w-[150px]">
              <CustomImage
                src="/images/homepage/intro-section/vogue.webp"
                fill
                className="object-contain mix-blend-difference"
                alt="vogue"
              />
            </div>
            <div className="relative m-2 h-[18px] min-w-[98px] bg-white lg:h-9 lg:min-w-[179px]">
              <CustomImage
                src="/images/homepage/intro-section/paper.webp"
                fill
                className="object-contain mix-blend-difference"
                alt="paper"
              />
            </div>
            <div className="relative m-2 h-[18px] min-w-[100px] bg-white lg:h-9 lg:min-w-[234px]">
              <CustomImage
                src="/images/homepage/intro-section/tech-crunch.webp"
                fill
                className="object-contain mix-blend-difference"
                alt="tech crunch"
              />
            </div>

            <div className="relative m-2 h-[17px] min-w-[64px] bg-white lg:h-[51px] lg:min-w-[183px]">
              <CustomImage
                src="/images/homepage/intro-section/firstclasse.webp"
                fill
                className="object-contain mix-blend-difference"
                alt="firstclasse"
              />
            </div>
            <div className="relative m-2 h-[18px] min-w-[65px] bg-white lg:h-9 lg:min-w-[136px]">
              <CustomImage
                src="/images/homepage/intro-section/brex.webp"
                fill
                className="object-contain mix-blend-difference"
                alt="brex"
              />
            </div>
            <div className="relative m-2 h-4 min-w-[50px] bg-white lg:h-[34px] lg:min-w-[127px]">
              <CustomImage
                src="/images/homepage/intro-section/san-francisco.webp"
                fill
                className="object-contain  mix-blend-difference"
                alt="san francisco business times"
              />
            </div>
          </div>
          <div className="mt-10 flex justify-center">
            <div className="flex gap-x-2">
              <div className="max-w-[72px]">
                <CustomImage
                  src="/images/homepage/top-fold/review-avatar.webp"
                  alt="avatar reviewer"
                  width={145}
                  height={92}
                  quality={100}
                />
              </div>
              <div>
                <div className="flex">
                  <StarIcon className="h-6 w-6 text-[#FFD330]" fontSize={18} />
                  <StarIcon className="h-6 w-6 text-[#FFD330]" fontSize={18} />
                  <StarIcon className="h-6 w-6 text-[#FFD330]" fontSize={18} />
                  <StarIcon className="h-6 w-6 text-[#FFD330]" fontSize={18} />
                  <StarIcon className="h-6 w-6 text-[#FFD330]" fontSize={18} />
                </div>
                <p className="text-[10px] opacity-50 mix-blend-difference">Trusted and loved by many</p>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div
        className={classNames('mb-28 overflow-hidden', '-mt-[calc(100vh-100px)]')}
        style={{ transform: 'translate3d(0,0,0)' }}
      >
        <ArtWorkGrid />
      </div>
    </div>
  )
}
export default TopFold
