import { cohartExternalLinks } from 'configs'

export const generateHomepageJsonLd = (): { __html: string } => {
  return {
    __html: `{
        "@context": "http://schema.org",
        "@type": "Organization",
        "name": "Cohart",
        "logo": "https://www.cohart.com/favicon.ico",
        "sameAs": [
          "${cohartExternalLinks.instagram}",
          "${cohartExternalLinks.twitter}",
          "${cohartExternalLinks.linkedIn}",
          "${cohartExternalLinks.tiktok}"
        ],
        "url": "https://www.cohart.com",
        "contactPoint": {
          "@type": "ContactPoint",
          "contactType": "customer support",
          "url": "https://www.cohart.com/contact-us"
        }
    }`,
  }
}
