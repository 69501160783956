import Metadata from 'shared/components/Metadata'
import dynamic from 'next/dynamic'
import Header from 'shared/components/layout/Header'
import TopFold from 'views/homepage/components/TopFold'
import useAuth from 'hooks/authentication/useAuth'
import { generateHomepageJsonLd } from 'views/homepage/utils/generateHomepageJsonLd'

const UserExplorer = dynamic(() => import('views/homepage/components/UserExplorer'), { ssr: false })
const MissionSection = dynamic(() => import('views/homepage/components/MissionSection'), { ssr: false })
const Testimonials = dynamic(() => import('views/homepage/components/Testimonials'), { ssr: false })
const ExploreBanner = dynamic(() => import('views/homepage/components/ExploreBanner'), { ssr: false })
const FounderSection = dynamic(() => import('views/homepage/components/FounderSection'), { ssr: false })
const JoinTeamBanner = dynamic(() => import('views/homepage/components/JoinTeamBanner'), { ssr: false })
const InvestorSection = dynamic(() => import('views/homepage/components/InvestorSection'), { ssr: false })
const NewsBanner = dynamic(() => import('views/homepage/components/NewsBanner'), { ssr: false })
const FooterSection = dynamic(() => import('shared/components/layout/FooterSection'), { ssr: false })

const HomePage = () => {
  const { isLoggedIn, isFetching: isAuthFetching } = useAuth()

  return (
    <>
      <Metadata url="/" jsonLd={generateHomepageJsonLd()} jsonLdKey={'homepageJsonLdKey'} />
      <div className="snap-y snap-proximity font-monument-grotes">
        <Header />

        <div>
          <TopFold isLoggedIn={!!isLoggedIn} isAuthFetching={isAuthFetching} />
          <MissionSection />
          <UserExplorer />
          <ExploreBanner isLoggedIn={!!isLoggedIn} isAuthFetching={isAuthFetching} />
          <Testimonials />
          <FounderSection />
          <JoinTeamBanner />
          <InvestorSection />
          <div className="px-7 lg:px-[88px]">
            <NewsBanner />
          </div>
        </div>
        <FooterSection />
      </div>
    </>
  )
}

export default HomePage
