import ArtWorkView from './ArtWorkView'

const ArtWorkGrid = () => {
  return (
    <div className="grid grid-flow-row auto-rows-auto grid-cols-12 gap-4 gap-x-5 py-20">
      {/* Artwork 1st row */}
      <div className="col-span-5 col-start-1 mt-[170px] lg:col-span-3 lg:mt-20">
        <ArtWorkView
          frontImage="/images/homepage/top-fold/front/Image.webp"
          backImage="/images/homepage/top-fold/back/Image.webp"
          artist="Grace"
          name="Genevieve Leavold"
          url="/artworks/7364"
          width={728}
          height={546}
          alignment="right"
        />
      </div>
      <div className="col-span-4 col-start-9 lg:col-span-3 lg:col-start-10">
        <ArtWorkView
          frontImage="/images/homepage/top-fold/front/Image-1.webp"
          backImage="/images/homepage/top-fold/back/Image-1.webp"
          artist="Theophilus Tetteh"
          name="Youth Diaries, Shades and Hats 10"
          url="/artworks/5537"
          width={542}
          height={656}
        />
      </div>

      {/* Artwork 2nd row */}
      <div className="col-span-4 col-start-2 mt-20 lg:col-span-3 lg:col-start-2">
        <ArtWorkView
          frontImage="/images/homepage/top-fold/front/Image-2.webp"
          backImage="/images/homepage/top-fold/back/Image-2.webp"
          artist="Dylan Gill"
          name="Celestial Bloom"
          url="/artworks/6103"
          width={542}
          height={810}
        />
      </div>
      <div className="col-span-4 col-start-7 mt-[110px] lg:col-span-3 lg:col-start-8">
        <ArtWorkView
          frontImage="/images/homepage/top-fold/front/Image-3.webp"
          backImage="/images/homepage/top-fold/back/Image-3.webp"
          artist="Manuela Karin Knaut"
          name="Zest for Life"
          url="/artworks/5844"
          width={483}
          height={483}
        />
      </div>
    </div>
  )
}

export default ArtWorkGrid
