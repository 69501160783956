import { motion } from 'framer-motion'
import CustomImage from 'shared/components/CustomImage'
import cn from 'classnames'
import Link from 'next/link'

type Props = {
  frontImage: string
  backImage: string
  artist: string
  name: string
  url: string
  alignment?: string
  width?: number
  height?: number
}

const ArtWorkView = ({ frontImage, backImage, width, height, artist, name, url, alignment = 'left' }: Props) => {
  return (
    <Link passHref href={url} legacyBehavior>
      <motion.a
        transition={{ duration: 0.3 }}
        className="relative z-[2] cursor-pointer"
        style={{ maxWidth: width + 'px' }}
      >
        {/* front image */}
        <motion.div className="relative inline-flex overflow-hidden rounded-md shadow-cohart-xl" whileHover="hover">
          <CustomImage src={frontImage} alt={`${name} - ${artist}`} width={width} height={height} />
          <motion.div
            className="absolute left-0 top-0 z-[3] h-full w-full bg-black/50"
            initial={{ opacity: 0 }}
            whileHover={{ opacity: 1 }}
            transition={{ duration: 0.5 }}
            variants={{
              rest: { opacity: 0 },
              hover: {
                opacity: 1,
                transition: {
                  duration: 0.3,
                  type: 'tween',
                  ease: 'easeOut',
                },
              },
            }}
          >
            <CustomImage src={backImage} alt={`${name} - ${artist}`} className="h-auto w-full object-cover" fill />
          </motion.div>
        </motion.div>

        <div className={cn('mt-1 lg:mt-4 ', alignment === 'left' ? 'text-left' : 'text-right')}>
          <p className="text-[10px] text-[#898788] lg:text-2xl">{name}</p>
          <p className="text-[8px] text-[#898788] lg:text-xl">{artist}</p>
        </div>
      </motion.a>
    </Link>
  )
}

export default ArtWorkView
